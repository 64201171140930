<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
</style> 
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Debit Note Manual</h4>
    </span>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Supplier</label>
        <multiselect
          class="selectExample"
          v-model="supplier"
          :options="optionSupplier"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Name"
          :disabled="false"
          @select="supplierChange($event)"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.Code }} - {{ dt.option.Name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.Code }} - {{ dt.option.Name }}</span>
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-if="supplier == null && status_submit"
          >This field is required</span
        >
      </div>
       <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="Dn Number"
          name="Dn Number"
          v-model="dn_number"
          readonly
        />
       </div>
    </div>
    
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Ownership</label>
        <multiselect
          class="selectExample"
          v-model="ownership.selected"
          :options="ownership.options"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          label="name"
          @select="ownershipChange($event)"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option }}</span>
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-if="ownership.selected == null && status_submit"
          >This field is required</span
        >
      </div>
      <div class="vx-col w-1/2">
      <label class="vs-input--label">DN Type</label>
        <multiselect
          class="selectExample"
          v-model="dnType.selected"
          :options="dnType.options"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          label="Name"
          track-by="Name"
          @select="dnTypeChange($event)"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.Name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.Name }}</span>
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-if="dnType.selected == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>

    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full"
          label="Reference Code"
          name="Reference Code"
          v-model="reference_code"
          placeholder="Reference Code"
          v-validate="'required'"
        />
        <span
          class="text-danger text-sm"
          v-if="reference_code == null && status_submit"
          >This field is required</span
        >
      </div>
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Dn Date</label>
        <datepicker
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="DN Date"
          v-model="dn_date"
          placeholder="DN Date"
          :disabled-dates="{ from: new Date() }"
        ></datepicker>
        <span
          class="text-danger text-sm"
          v-if="dn_date == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full"
          label="Assignment Code"
          name="Assignment Code"
          v-model="assignment_code"
          placeholder="Assignment Code"
        />
      </div>
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Dn Posting Date</label>
        <datepicker
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="DN Posting Date"
          v-model="dn_posting_date"
          placeholder="DN Posting Date"
          :disabled-dates="{ from: new Date() }"
        ></datepicker>
        <span
          class="text-danger text-sm"
          v-if="dn_posting_date == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full"
          label="Supplier CN/SOA Code"
          name="Supplier CN/SOA Code"
          v-model="cn_number"
          placeholder="Supplier CN/SOA Code"
        />
      </div>
      <div class="vx-col w-1/2" v-if="disableVat == false">
        <label class="vs-input--label">VAT OUT</label>
        <multiselect
          class="selectExample"
          v-model="tax.selected"
          :options="tax.options"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          label="Name"
          track-by="Name"
          @select="onSelectTax($event)"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.Name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.Name }}</span>
            </div>
          </template>
        </multiselect>
      </div>
      <div class="vx-col w-1/2" v-else>
        <vs-input
          class="w-full input-readonly"
          label="VAT OUT"
          name="VAT OUT"
          readonly
        />  
      </div>
    </div>
    
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">       
        <label class="vs-input--label">Supplier CN Date/SOA Date</label>
        <datepicker
          class="w-full"
          :inline="false"
          name="Supplier CN Date/SOA Date"
          v-model="cn_date"
          placeholder="Select Supplier CN Date/SOA Date"
          v-validate="'required'"
          :disabled-dates="{ from: new Date() }"
        ></datepicker>        
        <span
          class="text-danger text-sm"
          v-if="cn_date == null && status_submit"
          >This field is required</span
        >
      </div>
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Settlement Method</label>
        <multiselect
          class="selectExample"
          v-model="settlement.selected"
          :options="settlement.options"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          label="name"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option }}</span>
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-if="settlement.selected == null && status_submit"
          >This field is required</span
        >
        <!-- <vs-input
          class="w-full input-readonly"
          label="Settlement Method"
          name="Settlement Method"
          v-model="settlement.selected"
          readonly
        />   -->
      </div>
    </div>

    <div class="vx-row mb-2">
      <div class="vx-col w-1/3">
        <label class="vs-input--label">Description</label>
      </div>
    </div>

    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-textarea
          class="w-full"
          name="Description"
          v-model="notes"
        />
      </div>
    </div>
    
    <vs-divider style="width: 100%; margin-left: 2%">Attachment</vs-divider>
    <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>File</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <div class="vx-col sm:w-4/5 w-full">
          <input
            id="fileInput"
            name="file"
            class="w-full inputx"
            type="file"
            ref="file"
            multiple="multiple"
            accept=".jpg, .jpeg, .png, .pdf, .xlsx, .xlx, .doc, .docx"
          />
        </div>
      </div>
    </div>

    <br><br>

    <div class="vx-col sm:w-5/5 w-full mb-2" v-for="(tr, indextr) in items" :key="indextr">
      <template v-if="dnType.selected == null || dnType.selected && dnType.selected.IsUseCoa == 0">
        <div class="vx-row mb-2">
          <div class="vx-col w-2/5">
            <label class="vs-input--label">Operating Unit</label>
          </div>
          <div class="vx-col w-2/5">
            <label class="vs-input--label">Territory</label>
          </div>
          <div class="vx-col w-1/5">
            <label class="vs-input--label"></label>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-2/5">
            <multiselect
              class="selectExample"
              v-model="items[indextr].operatingUnit.selected"
              :options="optionOperatingUnit"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="160"
              :limit="4"
              placeholder=" Type to search"
              track-by="ID"
              label="Name"
              :disabled="false"
              @select="onSelectOu($event, indextr)"
            >
              <template slot="singleLabel" slot-scope="dt">
                <span class="option__desc">
                  <span class="option__title">{{ dt.option.Name }}</span>
                </span>
              </template>

              <template slot="option" slot-scope="dt">
                <div class="option__desc">
                  <span class="option__title">{{ dt.option.Name }}</span>
                </div>
              </template>
            </multiselect>
            <span
              class="text-danger text-sm"
              v-if="items[indextr].operatingUnit.selected == null && status_submit"
              >This field is required</span
            >
          </div>
          <div class="vx-col w-2/5">
            <multiselect
              class="selectExample"
              v-model="items[indextr].territory.selected"
              :options="items[indextr].territory.options"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="160"
              :limit="4"
              placeholder=" Type to search"
              track-by="ID"
              label="name"
              :disabled="false"
            >
              <template slot="singleLabel" slot-scope="dt">
                <span class="option__desc">
                  <span class="option__title">{{ dt.option.name }}</span>
                </span>
              </template>

              <template slot="option" slot-scope="dt">
                <div class="option__desc">
                  <span class="option__title">{{ dt.option.name }}</span>
                </div>
              </template>
            </multiselect>
            <span
              class="text-danger text-sm"
              v-if="items[indextr].territory.selected == null && status_submit"
              >This field is required</span
            >
          </div>
          
          <div class="vx-col w-1/5" v-if="ownership.selected == 'Operating Unit'">
            <vs-button
                  v-if="items[indextr].status=='minus'"
                  style="display: table-cell;"
                  v-on:click="handleRemoveItem(indextr)"
                  color="danger"
                  type="filled"
                  icon-pack="feather"
                  icon="icon-minus"
              ></vs-button>
              <vs-button
                  v-else
                  style="display: table-cell;"
                  v-on:click="handleAddItem()"
                  color="success"
                  type="filled"
                  icon-pack="feather"
                  icon="icon-plus"
              ></vs-button>
          </div>
        </div>

        <div class="vx-row mb-2">
          <div class="vx-col w-1/3">
            <label class="vs-input--label">Line Item</label>
          </div>
          <div class="vx-col w-1/3">
            <label class="vs-input--label">VAT OUT</label>
          </div>
          <div class="vx-col w-1/3">
            <label class="vs-input--label">Total DN Value</label>
          </div>
        </div>

        <div class="vx-row mb-2">
          <div class="vx-col w-1/3">
            <vs-input
              v-validate="'required'"
              class="w-full"
              name="DN Value"
              v-model="items[indextr].dn_value"
              @input="calculate($event, indextr)"
              @keypress="isNumber($event)"
            />
            <span
              class="text-danger text-sm"
              v-if="items[indextr].dn_value == null && status_submit"
              >This field is required</span
            >
          </div>
          <div class="vx-col w-1/3">
            <vs-input
              class="w-full input-readonly"
              readonly
              name="VAT OUT"
              v-model="items[indextr].tax_value"
            />
          </div>
          <div class="vx-col w-1/3">
            <vs-input
              v-validate="'required'"
              class="w-full input-readonly"
              readonly
              name="Total DN Value"
              v-model="items[indextr].total_dn_value"
            />
          </div>
        </div>

      </template>

      <template v-if="dnType.selected && dnType.selected.IsUseCoa == 1">
        <div class="vx-row mb-2">
          <!-- <div class="vx-col w-1/4">
            <label class="vs-input--label">COA</label>
          </div> -->
          <div class="vx-col w-1/3">
            <label class="vs-input--label">Operating Unit</label>
          </div>
          <div class="vx-col w-1/3">
            <label class="vs-input--label">Territory</label>
          </div>
          <div class="vx-col w-1/3">
            <label class="vs-input--label">Cost Centre</label>
          </div>
        </div>
        <div class="vx-row mb-2">
          <!-- <div class="vx-col w-1/4">
            <multiselect
              class="selectExample"
              v-model="items[indextr].coa.selected"
              :options="optionCoa"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="160"
              :limit="4"
              placeholder=" Type to search"
              track-by="ID"
              label="Name"
              :disabled="false"
              :show-labels="false"
            >
              <template slot="singleLabel" slot-scope="dt">
                <span class="option__desc">
                  <span class="option__title">{{ dt.option.Code }} - {{ dt.option.Name }}</span>
                </span>
              </template>

              <template slot="option" slot-scope="dt">
                <div class="option__desc">
                  <span class="option__title">{{ dt.option.Code }} - {{ dt.option.Name }}</span>
                </div>
              </template>
            </multiselect>
          </div> -->
          
          <div class="vx-col w-1/3">
            <multiselect
              class="selectExample"
              v-model="items[indextr].operatingUnit.selected"
              :options="optionOperatingUnit"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="160"
              :limit="4"
              placeholder=" Type to search"
              track-by="ID"
              label="Name"
              :disabled="false"
              :show-labels="false"
              @select="onSelectOu($event, indextr)"
            >
              <template slot="singleLabel" slot-scope="dt">
                <span class="option__desc">
                  <span class="option__title">{{ dt.option.Name }}</span>
                </span>
              </template>

              <template slot="option" slot-scope="dt">
                <div class="option__desc">
                  <span class="option__title">{{ dt.option.Name }}</span>
                </div>
              </template>
            </multiselect>
            <span
              class="text-danger text-sm"
              v-if="items[indextr].operatingUnit.selected == null && status_submit"
              >This field is required</span
            >
          </div>
          <div class="vx-col w-1/3">
            <multiselect
              class="selectExample"
              v-model="items[indextr].territory.selected"
              :options="items[indextr].territory.options"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="160"
              :limit="4"
              placeholder=" Type to search"
              track-by="ID"
              label="name"
              :disabled="false"
              :show-labels="false"
            >
              <template slot="singleLabel" slot-scope="dt">
                <span class="option__desc">
                  <span class="option__title">{{ dt.option.name }}</span>
                </span>
              </template>

              <template slot="option" slot-scope="dt">
                <div class="option__desc">
                  <span class="option__title">{{ dt.option.name }}</span>
                </div>
              </template>
            </multiselect>
            <span
              class="text-danger text-sm"
              v-if="items[indextr].territory.selected == null && status_submit"
              >This field is required</span
            >
          </div>
          <div class="vx-col w-1/3">
            <multiselect
              class="selectExample"
              v-model="items[indextr].costCenter.selected"
              :options="optionCostCenter"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="160"
              :limit="4"
              placeholder=" Type to search"
              track-by="ID"
              label="Name"
              :disabled="false"
              :show-labels="false"
            >
              <template slot="singleLabel" slot-scope="dt">
                <span class="option__desc">
                  <span class="option__title">{{ dt.option.Code }} - {{ dt.option.Name }}</span>
                </span>
              </template>

              <template slot="option" slot-scope="dt">
                <div class="option__desc">
                  <span class="option__title">{{ dt.option.Code }} - {{ dt.option.Name }}</span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>

        <div class="vx-row mb-2">
          <div class="vx-col w-1/4">
            <label class="vs-input--label">Line Item</label>
          </div>
          <div class="vx-col w-1/4">
            <label class="vs-input--label">VAT OUT</label>
          </div>
          <div class="vx-col w-1/4">
            <label class="vs-input--label">Total DN Value</label>
          </div>
          <div class="vx-col w-1/4">
            <label class="vs-input--label"></label>
          </div>
        </div>

        <div class="vx-row mb-2">
          <div class="vx-col w-1/4">
            <vs-input
              v-validate="'required'"
              class="w-full"
              name="DN Value"
              v-model="items[indextr].dn_value"
              @input="calculate($event, indextr)"
              @keypress="isNumber($event)"
            />
            <span
              class="text-danger text-sm"
              v-if="items[indextr].dn_value == null && status_submit"
              >This field is required</span
            >
          </div>
          <div class="vx-col w-1/4">
            <vs-input
              class="w-full input-readonly"
              readonly
              name="VAT OUT"
              v-model="items[indextr].tax_value"
            />
          </div>
          <div class="vx-col w-1/4">
            <vs-input
              v-validate="'required'"
              class="w-full input-readonly"
              readonly
              name="Total DN Value"
              v-model="items[indextr].total_dn_value"
            />
          </div>
          <div class="vx-col w-1/4" v-if="ownership.selected == 'Operating Unit'">
            <vs-button
                v-if="items[indextr].status=='minus'"
                style="display: table-cell;"
                v-on:click="handleRemoveItem(indextr)"
                color="danger"
                type="filled"
                icon-pack="feather"
                icon="icon-minus"
            ></vs-button>
            <vs-button
                v-else
                style="display: table-cell;"
                v-on:click="handleAddItem()"
                color="success"
                type="filled"
                icon-pack="feather"
                icon="icon-plus"
            ></vs-button>
          </div>
        </div> 
      </template> 

      <div class="vx-row mb-2">
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Note</label>
        </div>
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Assignment</label>
        </div>
      </div>

      <div class="vx-row mb-2">
        <div class="vx-col w-1/2">
          <vs-textarea
            class="w-full"
            name="Note"
            v-model="items[indextr].notes"
          />
        </div>

        <div class="vx-col w-1/2">
          <vs-textarea
            class="w-full"
            name="Assignment"
            v-model="items[indextr].assignments"
          />
        </div>
      </div>

      <vs-divider style="width: 100%;" v-if="indextr != items.length-1"></vs-divider>
    </div>

    <br><br>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/3">
      </div>
      <div class="vx-col w-1/3" style="text-align:right">        
          <label class="vs-input--label"><b>Grand Total</b></label>
      </div>
      <div class="vx-col w-1/3">
        <vs-input
          v-validate="'required'"
          class="w-full input-readonly"
          readonly
          name="Grand Total"
          v-model="grand_total"
        />
      </div>
    </div>

    <div class="vx-row mb-6 mt-6">
      <div class="vx-col w-full" style="margin-bottom: 150px">
        <vs-button class="mb-2" @click="handleSubmit"
          >Save</vs-button
        >
      </div>
    </div>

    <div>
      <vs-prompt
        color="primary"
        title="Success"
        @cancel="closeConfirm"
        @close="closeConfirm"
        :buttons-hidden="true"
        :active.sync="activePrompt"
      >
        <div class="con-exemple-prompt">
          Debit Note <b>{{ docNumber }}</b> has been successfully created
          <br />
          <br />
        </div>
      </vs-prompt>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
export default {
  components: {
    Datepicker,
  },
  props: {},
  created() {},
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        search: "",
        offset: 0,
        limit: 10,
        status_submit: false,
        activePrompt: false,
        cn_number: null,
        cn_date: new Date(),
        tr_number: null,
        tr_date: null,
        supplier: null,
        optionSupplier: [],
        optionTerritory: [],
        optionCoa: [],
        optionCostCenter: [],
        notes: null,
        fileAttachment: [],
        paramsUpload:[],
        dnType: {
          options: [],
          selected: null
        },
        tax: {
          options: [],
          selected: null
        },
        coa: {
          options: [],
          selected: null
        },
        optionOperatingUnit: [],
        ownership: {
          options: ["Operating Unit","Head Office"],
          selected: null
        },
        settlement: {
          options: ["Payment", "AP Offset"],
          selected: "Payment"
        },
        id: 0,
        dn_number: null,
        reference_code: null,
        assignment_code: null,
        dn_date: new Date(),
        dn_posting_date: new Date(),   
        grand_total: 0,         
        tax_value: 0,     
        dn_value: 0,
        total_dn: 0,      
        operatingUnitIDs: [],      
        territoryIDs: [],    
        dNValues: [],    
        taxValues: [],    
        totalDNValues: [],  
        disableVat : false,
        notes_line: [],
        assignments_line: [],
        coa_id: [],
        cost_center_id: [],
        docNumber: "",
        items: [{
            status: "plus",
            tax_value : 0,
            dn_value : null,
            total_dn_value : 0,
            operatingUnit:{
                selected: null,
            },
            territory: {
              options: [],
              selected: null
            },
            notes: null,
            assignments: null,
            costCenter: {
              selected: null
            },
            coa: {
              selected: null
            },
        }],
      };
    },
    nameCoaIn ({ Code, Name }) {
      return `${Code} — [${Name}]`
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    closeConfirm() {
      this.activePrompt = false
      // this.handleClose();
    },
    paramData() {
      return {
        //dn_number: this.dn_number,
        territory_id: this.$userLogin.territory_id,
        territory_area_id: this.$userLogin.territory_area_id,
        supplier_address: this.supplier.Address,
        supplier_city: this.supplier.City,
        supplier_code: this.supplier.Code,
        supplier_contact_name: this.supplier.ContactName,
        supplier_name: this.supplier.Name,
        supplier_phone: this.supplier.Phone,
        supplier_id: this.supplier.ID,
        //territory_code: this.territory.code,
        //territory_id: this.territory.ID,
        //territory_name: this.territory.name,
        supplier_cn_number: this.cn_number,
        supplier_cn_date: this.cn_date,
        //tax_return_number: this.tr_number,
        //tax_return_date: this.tr_date,
        dn_value: this.dn_value,
        tax_value: this.tax_value,
        total_dn: this.total_dn,
        type: this.dnType.selected.Name,
        notes: this.notes,
        ownership: this.ownership.selected,
        debit_note_type_id: this.dnType.selected.ID,
        assignment_code : this.assignment_code,
        settlement_method : this.settlement.selected,
        dn_reference_code : this.reference_code,
        tax_id : this.tax.selected ? this.tax.selected.ID : null,
        dn_date: this.dn_date,
        posting_date : this.dn_posting_date,
        operating_unit_ids : this.operatingUnitIDs,
        territory_ids : this.territoryIDs,
        dn_values : this.dNValues,
        tax_values : this.taxValues,
        total_dn_values : this.totalDNValues,
        chart_of_account_in_id : this.coa.selected ? this.coa.selected.ID : null,
        notes_line : this.notes_line,
        assignments_line : this.assignments_line,
        //coa_id : this.coa_id,
        cost_center_id : this.cost_center_id
      };
    },
    handleSubmit() {
      this.status_submit = true;
      this.$validator.validateAll().then((result) => {

        var _this = this
        
        
        this.items.forEach(function(element, index){
          _this.operatingUnitIDs[index] = _this.items[index].operatingUnit.selected.ID
          _this.territoryIDs[index] = _this.items[index].territory.selected.ID
          _this.dNValues[index] = _this.items[index].dn_value
          _this.taxValues[index] = _this.items[index].tax_value
          _this.totalDNValues[index] = _this.items[index].total_dn_value
          _this.notes_line[index] = _this.items[index].notes
          _this.assignments_line[index] = _this.items[index].assignments
          // if (_this.items[index].coa.selected != null) {
          //   console.log("selected");
          //   _this.coa_id[index] = _this.items[index].coa.selected.ID
          // } else {
          //   _this.coa_id[index] = 0
          // }

          if (_this.dnType.selected && _this.dnType.selected.IsUseCoa == 1 && _this.items[index].costCenter.selected == null) {
            _this.$vs.notify({
              color: "danger",
              title: "Error",
              text: "Please choose a cost center",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });

            result = false
          }

          if ( _this.items[index].costCenter.selected != null) {
            _this.cost_center_id[index] = _this.items[index].costCenter.selected.ID
          } else {
            _this.cost_center_id[index] = 0
          }
        })

        if (result) {
          console.log(this.paramData(), "paramData");
          this.$vs.loading();
          this.postData();
        }
      });
    },
    postData() {
      this.$http
        .post("/api/v1/debit-note/manual", this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.id = resp.data.ID
            if (this.$refs.file.files.length > 0) {
              this.file = this.$refs.file.files;
              for(let i =0; i < this.$refs.file.files.length; i++) {
                this.uploadData(i)
              }
              document.querySelector("#fileInput").value = "";
            }
            this.docNumber = resp.data.Code;
            this.$vs.dialog({
              color: "success",
              title: "Success",
              text: "DN "+resp.data.Code+" has been successfully created",
              type: "alert",
            });
              
            console.log(this.docNumber, "docNumber");
            // this.activePrompt = true;
            this.handleClose();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    paramUpload(array) {
      var form = new FormData();
      form.append("id", this.id);
      form.append("file", this.file[array]);
      return form;
    },
    uploadData(array) {
      this.$vs.loading();
      this.$http
        .post("/api/v1/debit-note/upload-file", this.paramUpload(array), {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.fileAttachment.push({
            id: resp.data.attachment.ID,
            name: resp.data.attachment.NameFile,
            path: resp.data.attachment.PathFile,
          });
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    setSettlementMethod(supplier_id) {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/settlement-method", {
          params: {
            order: "id",
            sort: "desc",
            supplier_id:supplier_id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records[0]){
              this.settlement.selected = resp.data.records[0].SettlementMethod;
            }else{
              this.settlement.selected = "Payment";
            }
            
          }
          this.$vs.loading.close();
        });
    },
    getOptionSupplier() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/suppliers", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionSupplier = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Supplier option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionDebitNoteType(isHeadOffice) {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/debit-note-type", {
          params: {
            is_head_office: isHeadOffice,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.dnType.options = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get debit note type option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionTax() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/tax", {
          params: {
            order: "is_default",
            sort: "desc, name asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.tax.options = resp.data.records;
            this.tax.selected = resp.data.records[0];
        
            var _this = this
            this.items.forEach(function(element, index){
              _this.calculate(_this.items[index].total_dn_value, index)
            })
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get tax option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionCoa() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/chart-of-accounts", {
          params: {
            order: "code",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            // this.coa.options = resp.data.records;
            // this.coa.selected = resp.data.records[0];
            this.optionCoa = resp.data.records;
            // this.items[0].coa.selected = this.optionCoa.filter( x => x.Code == "71901100")[0]
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get coa option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionCostCenter() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/cost-center", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionCostCenter = resp.data.records;
            // this.items[0].costCenter.selected = this.optionCostCenter.filter( x => x.Code == "OTH")[0]
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Cost Center option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionOperatingUnit(isHeadOffice) {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/operating-unit-only", {
          params: {
            is_head_office: isHeadOffice,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionOperatingUnit = []
            this.optionOperatingUnit = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get operating unit option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    handleAddItem(){
      this.items.splice(this.items.length+1, 0, {
          status: "minus",
          tax_value : 0,
          dn_value : null,
          total_dn_value : 0,
          operatingUnit:{
              selected: null,
          },
          territory: {
            options: [],
            selected: null
          },
          notes: null,
          assignments: null,
          costCenter: {
            selected: null
          },
      })
      
    },
    handleRemoveItem(indextr){
      this.items.splice(indextr, 1)
    },
    supplierChange(val){
      console.log("supp", val)
      var supplier_id = val.ID ? val.ID : val.id;
        this.setSettlementMethod(supplier_id);
    },
    dnTypeChange(val){
      console.log("dnTypeChange", val)
      if (val.IsTax == 1){
        this.disableVat = false;
        this.getOptionTax();
      }else{     
        this.disableVat = true;   
        this.tax = {
          options: [],
          selected: null
        }
      }

      if (val.IsUseCoa == 1){
        // this.getOptionCoa();
        this.getOptionCostCenter();
      }else{        
        this.coa = {
          options: [],
          selected: null
        }
      }
    },
    onSelectTax(val){
      if (val){
        this.tax.selected = val
        var _this = this
        this.items.forEach(function(element, index){
          _this.calculate(_this.items[index].dn_value, index)
        })
      }
    },
    ownershipChange(val){
      var _this = this
      this.items.forEach(function(element, index){
        _this.items[index].operatingUnit.selected = null
      })
      if(val == "Head Office"){
        this.getOptionOperatingUnit("true");
        this.getOptionDebitNoteType("true");
        const itemLength = this.items.length
        this.items.splice(1, itemLength-1)
      }else{
        this.getOptionOperatingUnit("false");
        this.getOptionDebitNoteType("false");
      }      
    },
    onSelectOu(option, indextr) {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/operating-unit-territory", {
          params: {
            operating_unit_id : option.ID,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
              var _this = this
              _this.items[indextr].territory.options = []
              resp.data.records.forEach(function(e){
                _this.items[indextr].territory.options.push(e.Territory);
              })
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Territory option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    calculate(val, indextr) {
      if (val != ""){
        var _this = this
        var grand_total_dn = 0
        var total_tax = 0
        var total_dn = 0
        val = parseFloat(val.replace(/,/g, ''))
        console.log("calculate", val)

        if (this.tax.selected) {
          this.items[indextr].tax_value = this.tax.selected.Rate * val / 100
          this.items[indextr].total_dn_value = this.items[indextr].tax_value + val
        }else {
          this.items[indextr].total_dn_value = val
        }

        this.items.forEach(function(element, index){
          grand_total_dn = grand_total_dn + parseFloat(_this.items[index].total_dn_value.toString().replace(/,/g, ''))
          total_tax = total_tax + parseFloat(_this.items[index].tax_value.toString().replace(/,/g, ''))
          if (_this.items[index].dn_value != null) {
            total_dn = total_dn + parseFloat(_this.items[index].dn_value.toString().replace(/,/g, ''))
          }
          
        })

        this.items[indextr].tax_value = this.items[indextr].tax_value.toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        this.items[indextr].dn_value = this.items[indextr].dn_value.toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        this.items[indextr].total_dn_value = this.items[indextr].total_dn_value.toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        this.grand_total = grand_total_dn.toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        this.dn_value = total_dn.toString()
        this.tax_value = total_tax.toString()
        this.total_dn = this.grand_total.toString().replace(/,/g, '')
      }
      
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    
  },
  mounted() {
    this.getOptionSupplier();
    this.getOptionCostCenter();
    // this.getOptionCoa();
  },
  computed: {},
  watch: {
    supplier: function () {
      console.log(this.supplier);
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}

.horizontal-divider {
  border-top: 1px solid #c1bbff;
  padding: 5px;
  margin-top: 15px;
}
</style>